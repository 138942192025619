import { DataAccess } from "../../dataaccess/data.access";
import { MasterDefTag } from "../../pojo/MasterDefTag";
import { S25Util } from "../../util/s25-util";
import { Item } from "../../pojo/Item";
import { jSith } from "../../util/jquery-replacement";
import { SearchCriteriaType } from "../../pojo/SearchCriteriaI";
import { Cache, Invalidate } from "../../decorators/cache.decorator";
import { UserprefService } from "../userpref.service";
import TagWrapperI = MasterDefTag.TagWrapperI;
import MdTag = MasterDefTag.MdTag;
import TagTypeNames = MasterDefTag.TagTypeNames;
import MdTypes = MasterDefTag.types;
import { S25Const } from "../../util/s25-const";
import ObjectTypeIds = MasterDefTag.ObjectTypeIds;
import TagObjectTypeIds = MasterDefTag.TagObjectTypeIds;

export class MasterDefinitionTagsService {
    @Cache({ targetName: "MasterDefinitionTagsService", immutable: true })
    public static getTags(): Promise<MasterDefTag.TagWrapperI[]> {
        return UserprefService.getLoggedIn().then((isLoggedIn: boolean) => {
            if (!isLoggedIn) return [];
            let url = "/master/definition/tags/list.json";
            return DataAccess.get(DataAccess.injectCaller(url, "MasterDefinitionTagsService.getTags")).then((resp) => {
                //remove root from ANG-4518
                resp = resp.root || resp;
                return S25Util.array.forceArray(resp?.tags);
            });
        });
    }

    public static async getTag(
        tagId: number,
        itemTypeId?: Item.Data,
        itemName?: TagTypeNames,
    ): Promise<MasterDefTag.TagWrapperI> {
        let tag: TagWrapperI;
        if (tagId) {
            let url = `/master/definition/tags/details.json?tag_id=${tagId}`;
            !!itemTypeId && url.concat(`&itemTypeId=${itemTypeId}`);
            !!itemName && url.concat(`&itemName=${itemName}`);

            const [resp, error] = await S25Util.Maybe(
                DataAccess.get<{ tags: MasterDefTag.TagWrapperI[] }>(
                    DataAccess.injectCaller(url, "MasterDefinitionTagsService.getTag"),
                ),
            );
            if (error) return;
            tag = S25Util.array.forceArray(resp?.tags)[0];
        } else {
            tag = { tag_id: undefined, secGroups: [], members: S25Util.deepCopy(defaultMembers) };
        }

        tag.secGroups = S25Util.array.forceArray(tag.secGroups).map((group: any) => {
            return {
                itemName: group.groupName,
                itemId: group.groupId,
            };
        });

        jSith.forEach(tag.members, (key, member) => {
            const searchCriteriaName: SearchCriteriaType["type"] = getSearchCriteriaName(member.md_name);

            Object.assign(member, {
                tagType: member.md_name,
                searchCriteriaName: searchCriteriaName,
                formalName: searchCriteriaName,
                active: !!member.active,
                items: S25Util.array.forceArray(member.items),
                md_type: member.md_type,
            });
        });

        return tag;
    }

    @Invalidate({ serviceName: "MasterDefinitionTagsService", methodName: "getTags" })
    public static editTagsOnObject(
        mdType: MdTypes,
        itemTypeId: number,
        mdId: number,
        addedTagIds: number[],
        removedTagsIds: number[],
    ) {
        console.log("mdType", mdType);
        const payload = {
            mlType: getDbName(mdType),
            itemId: mdId,
            addedTags: addedTagIds,
            removedTags: removedTagsIds,
        };

        const url = `/master/definition/tags/bulk.json`; //?itemTypeId=${itemTypeId}&mlType=${mdType}`;
        return DataAccess.put(DataAccess.injectCaller(url, "MasterDefinitionTagsService.editTagsOnObject"), {
            root: { tags: payload },
        });
    }

    @Invalidate({ serviceName: "MasterDefinitionTagsService", methodName: "getTags" })
    public static updateTag(tagId: number, payload: TagWrapperI) {
        payload.members = payload.members.filter((md) => {
            return md.changed || md.addedItems?.length || md.removedItems?.length;
        });

        payload.members = payload.members.map((item) => {
            return {
                md_name: item.md_name,
                active: item.active,
                md_type: item.md_type,
                addedItems: item.addedItems?.map((add) => {
                    return { itemName: add.itemName, itemId: add.itemId };
                }),
                removedItems: item.removedItems?.map((remove) => {
                    return { itemName: remove.itemName, itemId: remove.itemId };
                }),
                items: [],
            };
        });

        let url = `/master/definition/tags.json`;
        url = !!tagId ? `${url}?tag_id=${tagId}` : url;
        return DataAccess.put(DataAccess.injectCaller(url, "MasterDefinitionTagsService.updateTag"), {
            root: { tags: payload },
        });
    }

    @Invalidate({ serviceName: "MasterDefinitionTagsService", methodName: "getTags" })
    public static deleteTag(tagId: number | string) {
        return DataAccess.delete(
            DataAccess.injectCaller(
                `/master/definition/tags.json?tag_id=${tagId}`,
                "MasterDefinitionTagsService.delete",
            ),
        );
    }

    public static isItemReadOnly(tags: MdTag[]) {
        return S25Util.array.forceArray(tags).every((tag: MdTag) => !tag.readOnly);
    }

    public static getDisplayName(itemTypeId: ObjectTypeIds, mdTitle: string): string {
        let display;
        switch (itemTypeId) {
            case TagObjectTypeIds.EventForm:
                display = { singular: "Event Form" };
                break;
            default:
                display = S25Const.itemId2Display[itemTypeId];
                break;
        }

        return `${display ? display.singular : ""} ${mdTitle}`.trim();
    }
}

/*
search criteria might not be named consistently throughout the app, so we need to map them to the correct name for use with tags
 */
function getSearchCriteriaName(md_name: string): SearchCriteriaType["type"] {
    switch (md_name) {
        case "event_categories":
        case "event_category":
            return "eventCategories";

        case "organization_categories":
        case "organization_category":
        case "org_categories":
        case "org_category":
            return "organizationCategories";

        case "location_categories":
        case "location_category":
            return "locationCategories";

        case "resource_categories":
        case "resource_category":
            return "resourceCategories";

        case "event_custom_attributes":
        case "event_form_custom_attributes":
        case "event_cust_atrb":
            return "eventCustomAttributes";

        case "organization_custom_attributes":
        case "org_cust_atrb":
            return "organizationCustomAttributes";

        case "contact_cust_atrb":
        case "contact_custom_attributes":
            return "contactCustomAttributes";

        case "location_custom_attributes":
        case "location_cust_atrb":
            return "locationCustomAttributes";

        case "resource_custom_attributes":
        case "resource_cust_atrb":
            return "resourceCustomAttributes";
        case "email_template":
            return "manualEmailTemplates";
        case "location_features":
        case "features":
            return "locationFeatures";
        case "event_roles":
            return "eventRoles";
        case "organization_types":
            return "organizationTypes";
        case "event_forms":
            return "formConfigs";
        case "location_layouts":
            return "locationLayouts";
        case "event_requirements":
            return "eventRequirements";

        default:
            // console.log("using default mdName", md_name);
            return md_name as SearchCriteriaType["type"];
    }
}

function getDbName(mdName: string): string {
    return mdName?.endsWith("category") ? mdName.replace("category", "categories") : mdName;
}

const defaultMembers: MasterDefTag.MasterDefI[] = [
    {
        md_name: MdTypes.event_categories,
        object_type: Item.Ids.Event,
        md_type: MasterDefTag.TagType.categories,
        active: false,
        items: [],
    },
    {
        md_name: MdTypes.location_categories,
        object_type: Item.Ids.Location,
        md_type: MasterDefTag.TagType.categories,
        active: false,
        items: [],
    },
    {
        md_name: MdTypes.resource_categories,
        object_type: Item.Ids.Resource,
        md_type: MasterDefTag.TagType.categories,
        active: false,
        items: [],
    },
    {
        md_name: MdTypes.organization_categories,
        object_type: Item.Ids.Organization,
        md_type: MasterDefTag.TagType.categories,
        active: false,
        items: [],
    },
    {
        md_name: MdTypes.contact_custom_attributes,
        object_type: Item.Ids.Contact,
        md_type: MasterDefTag.TagType.custom_attributes,
        active: false,
        items: [],
    },
    {
        md_name: MdTypes.event_custom_attributes,
        object_type: Item.Ids.Event,
        md_type: MasterDefTag.TagType.custom_attributes,
        active: false,
        items: [],
    },
    {
        md_name: MdTypes.event_form_custom_attributes,
        object_type: 11,
        md_type: MasterDefTag.TagType.custom_attributes,
        active: false,
        items: [],
    },
    {
        md_name: MdTypes.location_custom_attributes,
        object_type: Item.Ids.Location,
        md_type: MasterDefTag.TagType.custom_attributes,
        active: false,
        items: [],
    },
    {
        md_name: MdTypes.organization_custom_attributes,
        object_type: Item.Ids.Organization,
        md_type: MasterDefTag.TagType.custom_attributes,
        active: false,
        items: [],
    },
    {
        md_name: MdTypes.resource_custom_attributes,
        object_type: Item.Ids.Resource,
        md_type: MasterDefTag.TagType.custom_attributes,
        active: false,
        items: [],
    },
    {
        md_name: MdTypes.event_roles,
        object_type: Item.Ids.Event,
        md_type: MasterDefTag.TagType.roles,
        active: false,
        items: [],
    },
    //{md_name: MdTypes.contact_roles, object_type: Item.Ids.Contact, md_type: MasterDefTag.TagType.roles, active: false, items: []},
    //{md_name: MdTypes.event_types, object_type: Item.Ids.Event, md_type: MasterDefTag.TagType.roles, active: false, items: []},
    {
        md_name: MdTypes.location_features,
        object_type: Item.Ids.Location,
        md_type: MasterDefTag.TagType.features,
        active: false,
        items: [],
    },
    {
        md_name: MdTypes.email_template,
        object_type: 0,
        md_type: MasterDefTag.TagType.email_template,
        active: false,
        items: [],
    },
    {
        md_name: MdTypes.organization_types,
        object_type: Item.Ids.Organization,
        md_type: MasterDefTag.TagType.types,
        active: false,
        items: [],
    },
    {
        md_name: MdTypes.event_forms,
        object_type: 0,
        md_type: MasterDefTag.TagType.event_forms,
        active: false,
        items: [],
    },
    {
        md_name: MdTypes.location_layouts,
        object_type: Item.Ids.Location,
        md_type: MasterDefTag.TagType.layouts,
        active: false,
        items: [],
    },
    {
        md_name: MdTypes.event_requirements,
        object_type: Item.Ids.Event,
        md_type: MasterDefTag.TagType.requirements,
        active: false,
        items: [],
    },
];
