//author: devin
import { OlsService } from "./ols.service";
import { FlsService } from "./fls.service";
import { S25Util } from "../util/s25-util";
import { Cache } from "../decorators/cache.decorator";
import { UserprefService } from "./userpref.service";
import { AccessLevels } from "../pojo/Fls";

export class ObjectPermissionService {
    @Cache({ targetName: "ObjectPermissionService" })
    public static canEdit(itemId: number, itemTypeId: number): Promise<boolean> {
        itemId = S25Util.parseInt(itemId);
        itemTypeId = S25Util.parseInt(itemTypeId);

        if ([4, 6, 2, 3, 1].indexOf(itemTypeId) === -1) {
            return Promise.resolve(false);
        }

        return S25Util.all({
            fls: FlsService.getFls(),
            ols: itemTypeId !== 3 && OlsService.getOls([itemId], itemTypeId, "edit"),
            isLoggedIn: UserprefService.getLoggedIn(),
        }).then(function (resp) {
            let editAccess = resp.ols?.[0]?.access_level;
            if (itemTypeId === 3) {
                editAccess = AccessLevels.Full;
            }

            let canEditOls = ["C", "F"].indexOf(editAccess) > -1;
            let canEditFls = false;

            if (itemTypeId === 4) {
                canEditFls = ["C", "F"].indexOf(resp.fls.SPACE_LIST) > -1 || resp.fls.SPACE_PERM === "F";
            } else if (itemTypeId === 6) {
                canEditFls = ["C", "F"].indexOf(resp.fls.RESOURCE_LIST) > -1 || resp.fls.RESOURCE_PERM === "F";
            } else if (itemTypeId === 2) {
                canEditFls = ["C", "F"].indexOf(resp.fls.CU_ACCOUNT) > -1 || resp.fls.CU_PERM === "F";
            } else if (itemTypeId === 3) {
                canEditFls = ["C", "F"].indexOf(resp.fls.CU_CONTACT) > -1 || resp.fls.CU_PERM === "F";
            } else if (itemTypeId === 1) {
                canEditFls = ["C", "F"].indexOf(resp.fls.EVENT_EVS) > -1 || resp.fls.EVENT_PERM === "F";
            }

            return canEditOls && canEditFls && resp.isLoggedIn;
        });
    }

    // @Cache({ targetName: "ObjectPermissionService" })
    // public static async canUnassign(itemId: number, itemTypeId: 4 | 6) {
    //     return Promise.resolve(true)
    //     // const apResp = await AssignPolicyService.getForCurrentUser(itemTypeId, itemIds);
    //     // return apResp.map((ap) => {
    //     //     return {
    //     //         itemId: ap.itemId,
    //     //         canUnassign: ap.unassign === "unassign",
    //     //     };
    //     // });
    // }

    // canUnassign([1, 2 , 3], itemTypeId); // make the call and cache
    // canUnassign([2 , 3], itemTypeId); //makes the call AGAIN and caches, even thogh the values are already in cache

    /*
    @Cache({ targetName: "ObjectPermissionService" })
    public static canEventOccEdit(itemId: number, itemTypeId: number) {
        //maybe need more FLS check here....
        itemId = S25Util.parseInt(itemId);
        itemTypeId = S25Util.parseInt(itemTypeId);

        if ([1].indexOf(itemTypeId) === -1) {
            return Promise.resolve(false);
        }

        return S25Util.all({
            fls: FlsService.getFls(),
            ols: itemTypeId !== 3 && OlsService.getOls([itemId], itemTypeId, "edit"),
            isLoggedIn: UserprefService.getLoggedIn(),
        }).then(function (resp) {
            let editAccess = resp.ols && resp.ols.length && resp.ols[0] && resp.ols[0].access_level;
            let canEditOls = ["C", "F"].indexOf(editAccess) > -1;
            let canEditFls = false;
            canEditFls = ["C", "F"].indexOf(resp.fls.EVENT_LIST) > -1 || resp.fls.EVENT_PERM === "F";

            return canEditOls && canEditFls && resp.isLoggedIn;
        });
    } */
}
