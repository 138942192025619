//variable to cache item names by id and type id
//note we do not use the service-cache bc we collect item names elsewhere and want an easy
//way to add them to the cache, eg via putItemToItemName (see framework.js)

import { Timeout } from "../decorators/timeout.decorator";
import { S25Const } from "../util/s25-const";
import { S25Util } from "../util/s25-util";
import { ContactService } from "./contact.service";
import { EventService } from "./event.service";
import { OrganizationService } from "./organization.service";
import { ResourceService } from "./resource.service";
import { SpaceService } from "./space.service";
import { S25ItemNumericIdI } from "../pojo/S25ItemI";
import { Item } from "../pojo/Item";

export class NameService {
    private static keySep = "-";
    private static item2ItemName: { [key: string]: string } = {};
    private static DEFAULT_NAME = "Unknown";

    public static normalizeItemTypeId(itemTypeId: number) {
        itemTypeId = S25Util.toInt(itemTypeId);
        if (itemTypeId >= 10) {
            if (S25Const.itemType[itemTypeId]) {
                return S25Const.itemName2Id[S25Const.itemType[itemTypeId].type];
            }
        }
        return itemTypeId;
    }

    public static itemKey(item: S25ItemNumericIdI) {
        return item.itemId + NameService.keySep + item.itemTypeId;
    }

    private static async fetchAndMapItems<T>(
        items: S25ItemNumericIdI[],
        typeId: number,
        serviceMethod: (ids: number[]) => Promise<T[]>,
        keyProperty: keyof T,
        response: { [key: string]: string },
        mapKeyFn: (item: S25ItemNumericIdI) => string,
    ): Promise<void> {
        const filteredItems = items.filter((item) => item.itemTypeId === typeId);
        if (filteredItems.length === 0) return;

        const itemIds = filteredItems.map((item) => item.itemId);
        const fetchedItems = await serviceMethod(itemIds);

        filteredItems.forEach((item, idx) => {
            response[mapKeyFn(item)] = fetchedItems[idx][keyProperty] as string;
        });
    }

    private static processItemsWithCache(
        items: S25ItemNumericIdI[],
        response: { [key: string]: string },
        mapKeyFn: (item: S25ItemNumericIdI) => string,
        cacheSource: { [key: string]: string },
    ): S25ItemNumericIdI[] {
        return items.filter((item) => {
            const key = mapKeyFn(item);
            if (cacheSource[key]) {
                response[key] = cacheSource[key];
                return false;
            }
            return true;
        });
    }

    public static async getFormals(items: S25ItemNumericIdI[]) {
        const resp: { [key: string]: string } = {};
        items = items.map((item) => ({ ...item, itemTypeId: NameService.normalizeItemTypeId(item.itemTypeId) }));
        await Promise.all([
            NameService.fetchAndMapItems(
                items,
                Item.Ids.Location,
                SpaceService.getSpacesNameAndFormal,
                "itemDesc",
                resp,
                NameService.itemKey,
            ),
            NameService.fetchAndMapItems(
                items,
                Item.Ids.Organization,
                OrganizationService.getOrganizationsNameAndFormal,
                "itemDesc",
                resp,
                NameService.itemKey,
            ),
            NameService.fetchAndMapItems(
                items,
                Item.Ids.Contact,
                ContactService.getContactsNameAndFormal,
                "itemDesc",
                resp,
                NameService.itemKey,
            ),
        ]);
        return resp;
    }

    public static async getNames(items: S25ItemNumericIdI[]) {
        const resp: { [key: string]: string } = {};
        items = items.map((item) => ({ ...item, itemTypeId: NameService.normalizeItemTypeId(item.itemTypeId) }));
        items = NameService.processItemsWithCache(items, resp, NameService.itemKey, NameService.item2ItemName);
        await Promise.all([
            NameService.fetchAndMapItems(
                items,
                Item.Ids.Event,
                EventService.getEventsName,
                "itemName",
                resp,
                NameService.itemKey,
            ),
            NameService.fetchAndMapItems(
                items,
                Item.Ids.Location,
                SpaceService.getSpacesNameAndFormal,
                "itemName",
                resp,
                NameService.itemKey,
            ),
            NameService.fetchAndMapItems(
                items,
                Item.Ids.Organization,
                OrganizationService.getOrganizationsNameAndFormal,
                "itemName",
                resp,
                NameService.itemKey,
            ),
            NameService.fetchAndMapItems(
                items,
                Item.Ids.Contact,
                ContactService.getContactsNameAndFormal,
                "itemName",
                resp,
                NameService.itemKey,
            ),
            NameService.fetchAndMapItems(
                items,
                Item.Ids.Resource,
                ResourceService.getResourcesName,
                "itemName",
                resp,
                NameService.itemKey,
            ),
        ]);
        Object.keys(resp).forEach((key) => {
            const [itemId, itemTypeId] = key.split(NameService.keySep).map(Number);
            NameService.putItemToItemName(itemId, itemTypeId, resp[key]);
        });
        return resp;
    }

    @Timeout
    public static getFormal(itemId: number, itemTypeId: number) {
        return NameService.getFormals([{ itemId, itemTypeId }]).then(
            (formals) => formals[NameService.itemKey({ itemId, itemTypeId })],
        );
    }

    public static getFormalDebounced = S25Util.debounceTs(
        (items: S25ItemNumericIdI[]) => {
            return NameService.getFormals(items);
        },
        100,
        false,
        0,
        true,
    );

    @Timeout
    public static getName(itemId: number, itemTypeId: number) {
        return NameService.getNames([{ itemId, itemTypeId }]).then(
            (names) => names[NameService.itemKey({ itemId, itemTypeId })],
        );
    }

    public static getNameDebounced = S25Util.debounceTs(
        (items: { itemId: number; itemTypeId: number }[]) => {
            return NameService.getNames(items);
        },
        100,
        false,
        0,
        true,
    );

    public static putItemToItemName(itemId: number, itemTypeId: number, name?: string, onlyIfNotExists?: boolean) {
        name = S25Util.coalesce(name, NameService.DEFAULT_NAME);
        itemId = S25Util.toInt(itemId);
        itemTypeId = NameService.normalizeItemTypeId(itemTypeId);
        let key = NameService.itemKey({ itemId, itemTypeId });

        if (onlyIfNotExists) {
            if (S25Util.isUndefined(NameService.item2ItemName[key])) {
                NameService.item2ItemName[key] = name;
            }
        } else {
            NameService.item2ItemName[key] = name;
        }
    }
}
