//@author: devin
import { S25Util } from "../util/s25-util";
import { DataAccess } from "../dataaccess/data.access";
import { ListStateService } from "./list.state.service";
import { S25Const } from "../util/s25-const";
import { Cache } from "../decorators/cache.decorator";
import { Item } from "../pojo/Item";
import { Proto } from "../pojo/Proto";
import { TaskService } from "./task/task.service";
import { PreferenceService } from "./preference.service";
import { UserprefService } from "./userpref.service";
import ISODateString = Proto.ISODateString;
import { TaskTiersService } from "./task/task.tiers.service";

export class ListService {
    public static Comptype2SearchQuery: any = {
        "reservation-quick-search": "event_id|profile_id",
    };

    public static ListSortColMap: any = {
        creation_date: "create_dt",
        event: "event_name",
        title: "event_title",
        reference: "event_locator",
    };

    public static formParams(params: any) {
        if (params && params.modelBean && params.modelBean.optBean) {
            let startDate, endDate;
            let dateOption = params.modelBean.optBean.datesOption && params.modelBean.optBean.datesOption.value;

            if (!dateOption && params.modelBean.optBean.date && params.modelBean.optBean.endDate) {
                startDate = S25Util.date.toS25ISODateStrStartOfDay(params.modelBean.optBean.date);
                endDate = S25Util.date.toS25ISODateStrEndOfDay(params.modelBean.optBean.endDate);
            }

            //drop down searches have object type prefixed to favorite for favorite searches
            //this is only valid for reservation type searches. If the subject is just a plain object search we switch it to just
            //"favorite" instead of, say, "event_favorite" for an event search
            if (params.modelBean.searchQuery && params.modelBean.searchQuery.indexOf("favorite") > -1) {
                switch (params.modelBean.compsubject) {
                    case "event":
                        params.modelBean.searchQuery = params.modelBean.searchQuery.replace(
                            "event_favorite",
                            "favorite",
                        );
                        break;
                    case "location":
                        params.modelBean.searchQuery = params.modelBean.searchQuery.replace(
                            "space_favorite",
                            "favorite",
                        );
                        break;
                    case "resource":
                        params.modelBean.searchQuery = params.modelBean.searchQuery.replace(
                            "resource_favorite",
                            "favorite",
                        );
                        break;
                    case "organization":
                        params.modelBean.searchQuery = params.modelBean.searchQuery.replace(
                            "organization_favorite",
                            "favorite",
                        );
                        break;
                }
            }
            if (
                params.modelBean.searchQuery &&
                params.modelBean.compsubject === "location" &&
                params.modelBean.searchQuery.indexOf("spaces_") > -1
            ) {
                params.modelBean.searchQuery = params.modelBean.searchQuery.replace("spaces_direct", "direct");
                params.modelBean.searchQuery = params.modelBean.searchQuery.replace("spaces_favorite", "favorite");
                params.modelBean.searchQuery = params.modelBean.searchQuery.replace("spaces_min_ols", "min_ols");
                params.modelBean.searchQuery = params.modelBean.searchQuery.replace("spaces_can_assign", "can_assign");
            }

            let paramsUrl = "";
            paramsUrl += "?compsubject=" + params.modelBean.compsubject;
            paramsUrl += //see ANG-2294 for reservation_* and ANG-2313 for start_dt, end_dt
                startDate
                    ? (["event", "organization"].indexOf(params.modelBean.compsubject) > -1
                          ? "&reservation_start_dt="
                          : "&start_dt=") +
                      startDate +
                      (["event", "organization"].indexOf(params.modelBean.compsubject) > -1
                          ? "&reservation_end_dt="
                          : "&end_dt=") +
                      endDate
                    : "";
            paramsUrl +=
                params.modelBean.compsubject === "event" && dateOption === "futureOnly"
                    ? "&end_after=" + S25Util.date.toS25ISODateStrStartOfDay(new Date())
                    : dateOption === "recentHistory"
                      ? "&end_after=" + S25Util.date.toS25ISODateStrStartOfDay(S25Util.date.addMonths(new Date(), -18))
                      : "";
            paramsUrl += params.modelBean.compsubject === "event" ? "&node_type=E" : "";
            paramsUrl +=
                params.modelBean.sortCol && params.modelBean.sortCol.sort && params.modelBean.sortCol.order
                    ? "&order=" +
                      params.modelBean.sortCol.order +
                      "&sort=" +
                      (ListService.ListSortColMap[params.modelBean.sortCol.sort] || params.modelBean.sortCol.sort)
                    : "";
            paramsUrl += "&page=" + params.modelBean.chosen.page;
            paramsUrl += params.modelBean.itemsPerPage ? "&page_size=" + params.modelBean.itemsPerPage : "";
            paramsUrl +=
                "&obj_cache_accl=" +
                (params.modelBean.optBean.useCache === false ? "force" : S25Util.coalesce(params.modelBean.cacheId, 0));
            paramsUrl += params.modelBean.searchQuery ? params.modelBean.searchQuery : "";
            paramsUrl += params.modelBean.comptype ? "&comptype=" + params.modelBean.comptype : "";
            paramsUrl += params.modelBean.optBean.showRelatedLocations ? "&include=related" : "";
            if (
                params.modelBean.optBean.chosen &&
                params.modelBean.optBean.chosen.obj &&
                params.modelBean.optBean.chosen.obj.val
            ) {
                paramsUrl += "&" + params.modelBean.optBean.chosen.obj.val;
            }

            return "/list/listdata.json" + paramsUrl;
        }
    }

    public static formParams2(data: {
        itemType: Item.Subject;
        startDate?: ISODateString;
        endDate?: ISODateString;
        dateOption?: "recentHistory" | "futureOnly" | "allDates";
        sort?: { column: string; order?: "asc" | "desc" };
        page?: number;
        itemsPerPage?: number;
        useCache?: boolean;
        cacheId?: number;
        query?: string;
        comptype?: string;
        includeRelated?: boolean;
    }) {
        const {
            itemType,
            startDate,
            endDate,
            dateOption,
            sort,
            page,
            itemsPerPage,
            useCache,
            cacheId,
            query,
            comptype,
            includeRelated,
        } = data;
        const queryParams = [];
        queryParams.push(`compsubject=${itemType}`);
        const prefix = ["event", "organization"].includes(itemType) ? "reservation_" : "";
        if (startDate) {
            queryParams.push(`${prefix}start_dt=${startDate}`);
            queryParams.push(`${prefix}end_dt=${endDate}`);
        }
        if (itemType === "event") {
            queryParams.push(`node_type=E`);

            const today = S25Util.date.toS25ISODateStrStartOfDay(new Date());
            const recentHistory = S25Util.date.toS25ISODateStrStartOfDay(S25Util.date.addMonths(new Date(), -18));
            if (dateOption === "futureOnly") queryParams.push(`end_after=${today}`);
            else if (dateOption === "recentHistory") queryParams.push(`end_after=${recentHistory}`);
        }
        if (sort?.column && sort?.order)
            queryParams.push(`sort=${ListService.ListSortColMap[sort.column] || sort.column}`, `order=${sort.order}`);
        queryParams.push(`page=${page}`);
        if (itemsPerPage) queryParams.push(`page_size=${itemsPerPage}`);
        const acclCache = useCache === false ? "force" : (cacheId ?? 0);
        queryParams.push(`obj_cache_accl=${acclCache}`);
        if (query) queryParams.push(query.replace(/^&/, ""));
        if (comptype) queryParams.push(`comptype=${comptype}`);
        if (includeRelated) queryParams.push(`include=related`);
        const paramString = queryParams.join("&");

        return `/list/listdata.json?${paramString}`;
    }

    public static async getData(params: any) {
        const data = await DataAccess.get(
            DataAccess.injectCaller(ListService.formParams(params), "ListService.getData"),
        );
        return data || { count: 0, cols: [] };
    }

    public static async getData2(options: Parameters<typeof ListService.formParams2>[0]) {
        let data = await DataAccess.get(
            DataAccess.injectCaller(ListService.formParams2(options), "ListService.getData"),
        );
        data = data || { count: 0, cols: [], rows: [] };
        data.rows ??= [];
        return data;
    }

    @Cache({ immutable: true, targetName: "ListService" })
    public static getDataCached(params: any) {
        return ListService.getData(params);
    }

    public static scopeToDataRequest(scope: any) {
        scope.modelBean.optBean = scope.modelBean.optBean || {};

        return {
            modelBean: {
                optBean: {
                    date: scope.modelBean.optBean.date,
                    endDate: scope.modelBean.optBean.endDate,
                    datesOption: scope.modelBean.optBean.datesOption,
                    useCache: scope.modelBean.optBean.useCache,
                    useServiceCache: scope.modelBean.optBean.useServiceCache,
                    weekstart: scope.modelBean.optBean.weekstart,
                    chosen: scope.modelBean.optBean.chosen,
                    showRelatedEvents: scope.modelBean.optBean.showRelatedEvents,
                    showRelatedLocations: scope.modelBean.optBean.showRelatedLocations,
                    taskType: scope.modelBean.optBean.taskType,
                    multiQueryStr: scope.modelBean.optBean.multiQueryStr,
                },
                compsubject: scope.modelBean.compsubject,
                sortCol: scope.modelBean.sortCol,
                chosen: scope.modelBean.chosen,
                itemsPerPage: scope.modelBean.itemsPerPage,
                cacheId: scope.modelBean.cacheId,
                searchQuery: scope.modelBean.searchQuery,
                comptype: scope.modelBean.comptype,
                itemId: scope.modelBean.itemId,
                hasAssignedTo: scope.modelBean.hasAssignedTo,
                paginationCall: scope.modelBean.paginationCall,
            },
        };
    }

    public static setBeanSortCol(modelBean: any) {
        //used to set sortCol object using data, if already set, ignore and let component manange it
        if (modelBean.colChooseBean && !ListStateService.getListSortState(modelBean.listId)) {
            //if list sort state not already set
            modelBean.sortCol = modelBean.colChooseBean.colList.map((value: any) => {
                //extract sort column from data
                if (value.order) {
                    return {
                        sort: value.prefname,
                        order: value.order,
                    };
                }
            })[0];
            modelBean.sortCol &&
                modelBean.sortCol.sort &&
                ListStateService.setListSortState(modelBean.listId, modelBean.sortCol.sort, modelBean.sortCol.order); //set sort col
        }
    }

    public static getSearchQuery(compsubject: Item.Subject, comptype: string, checkedList: any[]) {
        var delim = "|",
            searchQuery = "";
        if (ListService.Comptype2SearchQuery[comptype]) {
            var components = ListService.Comptype2SearchQuery[comptype].split(delim);
            for (var i = 0; i < components.length; i++) {
                searchQuery += "&" + components[i] + "=";
                for (var j = 0; j < checkedList.length; j++) {
                    searchQuery += checkedList[j].split(delim)[i];
                    if (j + 1 < checkedList.length) {
                        searchQuery += "+";
                    }
                }
            }
            return searchQuery;
        }
        return "&" + S25Const.subject2ItemId[compsubject] + "=" + checkedList.join("+");
    }

    public static async getTaskData(options: Parameters<typeof TaskService.getTasks2>[0]) {
        // If this is not a saved search, then we need to set additional params
        if (!options.query.startsWith("&query_id")) {
            const params: string[] = [];

            // Additional params
            if (options.query === "Overdue") params.push(`task_state=1`, `task_type=2+3+4+5`);
            else if (options.query === "Outstanding") params.push(`outstanding=T`);
            else if (options.query === "Flagged") params.push(`unread=T`);
            else if (options.query === "Completed") params.push(`task_state=2+3`, `task_type=2+3+4+5`);
            else if (options.query === "Assigned_All")
                params.push(`assigned_by=${await UserprefService.getContactId()}`);

            // Start and End dates
            if (!["Outstanding", "Flagged"].includes(options.query)) {
                const prefs = await PreferenceService.getPreferences(["TasksDueStartDtRange", "TasksDueEndDtRange"]);
                const offsetStart = prefs["TasksDueStartDtRange"]?.value ?? 30;
                let offsetEnd = prefs["TasksDueEndDtRange"]?.value ?? 30;
                if (options.query === "Overdue" || options.query === "Completed") offsetEnd = 0;
                const today = S25Util.date.getDate(new Date());
                const start = S25Util.date.toS25ISODateTimeStr(
                    S25Util.date.addSeconds(S25Util.date.addDays(today, -offsetStart), 1),
                    true,
                );
                const end = S25Util.date.toS25ISODateStrEndOfDay(S25Util.date.addDays(today, offsetEnd));
                params.push(`due_start_dt=${start}`, `due_end_dt=${end}`);
            }
            options.query = params.join("&");
        }

        const [taskData, taskCount, prefs, userId, isWorkflowChained] = await Promise.all([
            TaskService.getTasks2(options),
            options.page === 1 && TaskService.getTasks2({ ...options, isCount: true }),
            PreferenceService.getPreferences(["25L_task_overview_columns"]),
            UserprefService.getContactId(),
            TaskTiersService.isWorkflowChained(),
        ]);
        const data = taskData.tasks;
        data.task = !data.task ? [] : !data.task.length ? [data.task] : data.task; // Force array

        const staticColumns = [
            { name: "Event", prefname: "event", isVisible: true, sortable: true },
            { name: "Title", prefname: "title", isVisible: true, sortable: true },
            { name: "Event State", prefname: "event_state", isVisible: true, sortable: true },
            { name: "Task Item", prefname: "task_item", isVisible: true, sortable: true },
            { name: "Type", prefname: "type", isVisible: true, sortable: true },
            { name: "Status", prefname: "status", isVisible: true, sortable: true },
            { name: "Actions", prefname: "actions", isVisible: true, sortable: false },
            { name: "Respond By", prefname: "respond_by", isVisible: true, sortable: true },
            { name: "First Date", prefname: "first_date", isVisible: true, sortable: true },
            { name: "Reference", prefname: "reference", isVisible: true, sortable: true },
            { name: "Assigned By", prefname: "assigned_by", isVisible: true, sortable: true },
            { name: "Assigned To", prefname: "assigned_to", isVisible: true, sortable: true },
            { name: "Scheduler", prefname: "scheduler", isVisible: true, sortable: true },
        ];
        const prefColumns: any = {};
        for (let column of prefs["25L_task_overview_columns"]?.value?.columns?.column || []) {
            prefColumns[column.name] = { name: column.title, isVisible: column.show === "true" };
        }
        const columns = staticColumns.map((column) => ({ ...column, ...prefColumns[column.prefname] }));
        const rows = data.task.map((task: any) => ({
            row: [
                { itemName: task.event_name, itemId: task.event_id, itemTypeId: 1 }, // Event Name
                task.event_title, // Event Title
                task.event_state, // State
                {
                    taskBlocked: task.task_blocked === "T",
                    itemName:
                        task.task_count > 1
                            ? `${task.task_name}: ${task.task_count} assignment requests`
                            : task.task_name,
                    itemTypeId: task.task_type,
                    itemId: parseInt(task.task_id),
                    eventId: task.event_id,
                    itemStateId: task.task_state,
                    assignedToId: task.assigned_to_id,
                    isTodo: parseInt(task.task_type) === 5 || parseInt(task.task_type) === 6,
                    todoType: task.task_subtype,
                    workflowChained: isWorkflowChained,
                }, // Item
                task.task_type_name, // Type
                task, // Status
                {
                    taskId: task.task_id,
                    taskBlocked: task.task_blocked === "T",
                    itemCount: task.task_count,
                    taskState: task.task_state,
                    taskType: task.task_type,
                    requesterId: task.requested_by_id,
                    eventId: task.event_id,
                    assignedToId: task.assigned_to_id,
                    todoType: task.todo_type,
                    todoSubType: task.task_subtype,
                    objectType: task.object_type,
                    objectId: task.object_id,
                }, // Actions
                task, // Respond By
                task.first_date, // First Date
                task.reference, // Event Reference
                TaskService.formSingleContactString(task.assigned_by, task.assigned_by_id, userId), // Assigned By
                TaskService.formSingleContactString(task.assigned_to, task.assigned_to_id, userId), // Assigned To
                TaskService.formSingleContactString(task.scheduler_name, task.scheduler_id, userId), // Scheduler
            ],
        }));
        if (rows.length) {
            rows[0].count = data.total_results;
            rows[0].pages = data.page_count || 1;
        }
        return {
            cols: columns,
            rows: rows,
            cacheId: data.paginate_key,
            page: data.page_num,
            page_size: options.pageSize || 25,
            taskCount: taskCount?.results?.tasks,
        };
    }
}
